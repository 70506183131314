import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import cx from "classnames";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

import {
  useStyles as useSectionStyles,
  useCommonStyles,
} from "../../../../utils/loan-application.styles";
import { borderColor } from "../../../../scss/colors.scss";
import { getLabel } from "../../../../utils/loan-application.utils";
import Button from "../../components/Button";

const useStyles = makeStyles((theme) => ({
  informationBox: {
    padding: "25px 20px",
    border: `1px solid ${borderColor}`,
    [theme.breakpoints.down("xs")]: {
      padding: "12px 16px",
    },
  },
  gridTemplate: {
    display: "grid",
    gridTemplate: "1fr 1fr / 1fr 1fr",
    gap: "12px 48px",
    [theme.breakpoints.down("xs")]: {
      gap: "12px",
    },
  },
  applicantName: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "24px",
  },
  infoText: {
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "22px",
  },
  unStyledText: {
    borderLeft: "none",
    marginBottom: "unset",
  },
  editButton: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  applicantCard: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

const SectionReviewStep = ({
  formik,
  onEdit,
  handlePrevStep,
  handleSaveSection,
}) => {
  const classes = useStyles();
  const sectionClasses = useSectionStyles();
  const commonClasses = useCommonStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      gridColumnGap="24px"
      gridRowGap="48px"
      width="100%"
    >
      <Box classes={{ root: classes.applicantCard }}>
        <Typography
          classes={{
            root: cx(sectionClasses.stepTitle, classes.unStyledText),
          }}
        >
          Applicant
        </Typography>
        <Box>
          <Typography
            classes={{ root: classes.editButton }}
            component="span"
            onClick={onEdit}
          >
            Edit
          </Typography>
        </Box>
        <Box
          classes={{ root: cx(classes.informationBox, classes.gridTemplate) }}
        >
          <Typography classes={{ root: classes.applicantName }}>
            {getLabel(formik.values.title)}.{" "}
            {formik.values.fullName.split(" ")[0]}
          </Typography>
          <Typography classes={{ root: classes.infoText }} align="right">
            {formik.values.mobileNumber}
          </Typography>
          <Typography classes={{ root: classes.infoText }}>
            Main Applicant
          </Typography>
          <Typography
            classes={{
              root: cx(classes.infoText, commonClasses.textOverflowEllipsis),
            }}
            align="right"
          >
            {formik.values.email}
          </Typography>
        </Box>
      </Box>
      {Array(formik.values.numberOfCoApplicants)
        .fill(null)
        .map((_, index) => (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={index.toString()}
            classes={{ root: classes.applicantCard }}
          >
            <Typography
              classes={{
                root: cx(sectionClasses.stepTitle, classes.unStyledText),
              }}
            >
              Applicant
            </Typography>
            <Box>
              <Typography
                classes={{ root: classes.editButton }}
                component="span"
                onClick={onEdit}
              >
                Edit
              </Typography>
            </Box>
            <Box
              classes={{ root: classes.informationBox }}
              display="flex"
              justifyContent="space-between"
              gridColumnGap="16px"
            >
              <Typography
                classes={{
                  root: cx(classes.infoText, commonClasses.widthFitContent),
                }}
              >
                Co-applicant
              </Typography>
              <Typography
                classes={{
                  root: cx(
                    classes.infoText,
                    commonClasses.textOverflowEllipsis
                  ),
                }}
                align="right"
              >
                {formik.values.coApplicants[index]}
              </Typography>
            </Box>
          </Box>
        ))}
      <Grid
        container
        xs={12}
        md={6}
        justifyContent="space-between"
        classes={{ root: sectionClasses.navigationButtonsContainer }}
      >
        <Button startIcon={<NavigateBeforeIcon />} onClick={handlePrevStep}>
          Previous
        </Button>
        <Button endIcon={<NavigateNextIcon />} onClick={handleSaveSection}>
          Save and Next
        </Button>
      </Grid>
    </Box>
  );
};

export default SectionReviewStep;
